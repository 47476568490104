import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Co je areografie?" />
    <h1>Co je areografie?</h1>
    <p>Areografie je vědeck&eacute; studium ekologick&yacute;ch are&aacute;lů. Are&aacute;lem je z&aacute;kladn&iacute; jednotka biogeografick&yacute;ch studi&iacute; a rozum&iacute; se j&iacute;m &uacute;zem&iacute; ob&yacute;van&eacute; dan&yacute;m taxonem.</p>
<p>Areografie je jedno z hlavn&iacute;ch t&eacute;mat biogeografie a makroekologie, zaměřuje se např&iacute;klad na rozložen&iacute; velikosti are&aacute;lů, tvar are&aacute;lů, vnitřn&iacute; strukturu are&aacute;lů aj.</p>
<hr />
<h2>Mapov&aacute;n&iacute; are&aacute;lu</h2>
<p>Při mapov&aacute;n&iacute; are&aacute;lů v aerografii lze v z&aacute;kladě postupovat těmito třemi postupy:</p>
<ul>
<li><strong>bodov&aacute; data</strong></li>
<li><strong>mapy are&aacute;lů</strong> (polygony)</li>
<li><strong>s&iacute;ťov&eacute; mapy</strong> (grid syst&eacute;m)</li>
</ul>
<h3>Bodov&aacute; data</h3>
<p>Při mapov&aacute;n&iacute; are&aacute;lů pomoc&iacute; bodov&yacute;ch dat označuj&iacute; jednotliv&eacute; body každ&yacute; zaznamenan&yacute; v&yacute;skyt druhu či společenstva opatřen&yacute; souřadnicemi.</p>
<p>Bodov&aacute; data v&yacute;skytu druhů jsou zanesena např&iacute;klad v česk&aacute; fytocenologick&eacute; <a href="http://florabase.cz/databanka/">datab&aacute;zi ČNFD</a> nebo např&iacute;klad ve velmi popul&aacute;rn&iacute; nadn&aacute;rodn&iacute; <a href="https://www.gbif.org/">datab&aacute;zi GBIF</a>.</p>
<h3>Mapy are&aacute;lů</h3>
<p>Mapy are&aacute;lů jsou polygony reprezentuj&iacute;c&iacute; are&aacute;ly druhů či společenstev, kter&eacute; byly vykresleny na z&aacute;kladě ter&eacute;nn&iacute;ch poznatků a expertn&iacute;ch znalost&iacute; autora. Polygony jsou obvykle velmi generalizovan&eacute;.</p>
<p>Tyto polygonov&eacute; mapy lze obvykle naj&iacute;t v encyklopedi&iacute;ch, atlasech či např&iacute;klad na webu <a href="https://www.iucnredlist.org/">IUCN Červen&yacute; list ohrožen&yacute;ch druhů</a>.</p>
<h3>S&iacute;ťov&eacute; mapy</h3>
<p>V s&iacute;ťov&yacute;ch map&aacute;ch je v&yacute;skyt (či početnost) druhů zaznamen&aacute;n v pravideln&eacute; s&iacute;ti buněk o určit&eacute; velikosti. Ve sv&eacute; podstatě jde o překonvertovan&aacute; bodov&aacute; data.</p>
<h4>S&iacute;ťov&eacute; mapov&aacute;n&iacute; v&nbsp;ČR</h4>
<p>Při s&iacute;ťov&eacute;m mapov&aacute;n&iacute; se v Česku dř&iacute;ve použ&iacute;val grid 10&nbsp;&times;&nbsp;10&nbsp;km, &uacute;zem&iacute; Česk&eacute; republiky tak pokr&yacute;valo 846 kvadr&aacute;tů.</p>
<p>V současnosti (2020) se použ&iacute;v&aacute; německ&aacute; s&iacute;ť Kartierung der Flora Mitteleuropas (KFME). Velikost gridu je 10&rsquo;&nbsp;z.&scaron;.&nbsp;&times;&nbsp;6&rsquo;&nbsp;z.d. (v&nbsp;česk&yacute;ch podm&iacute;nk&aacute;ch zhruba 12&nbsp;&times;&nbsp;11,1&nbsp;km, plocha 133,2&nbsp;km2). Celkem 678 kvadr&aacute;tů pokr&yacute;vaj&iacute;c&iacute; ČR se d&aacute;le děl&iacute; na čtvrtiny.</p>
<p>S&iacute;ťov&eacute; mapy založen&eacute; na gridu KFME lze prohl&iacute;dnout na webu <a href="https://pladias.cz/">PLADIAS</a>.</p>
<h4>S&iacute;ťov&eacute; mapov&aacute;n&iacute; v&nbsp;Evropě</h4>
<p>Při celoevropsk&eacute;m mapov&aacute;n&iacute; se obvykle použ&iacute;v&aacute; s&iacute;ť založen&aacute; na kartografick&eacute;m zobrazen&iacute; UTM, odpov&iacute;daj&iacute;c&iacute; gridu MGRS. Kvadr&aacute;ty maj&iacute; velikost 50&nbsp;&times;&nbsp;50&nbsp;km2. V m&iacute;stech mezi UTM poli se pak použ&iacute;vaj&iacute; kompenzačn&iacute;, lichoběžn&iacute;kov&eacute; buňky.</p>
<p>Na n&aacute;rodn&iacute; &uacute;rovni se v Evropě běžně použ&iacute;vaj&iacute; s&iacute;tě 10&nbsp;&times;&nbsp;10&nbsp;km2 nebo s&iacute;tě odvozen&eacute; od zeměpisn&yacute;ch souřadnic. K jejich zobrazen&iacute; jsou pak zpravidla použ&iacute;v&aacute;ny n&aacute;rodn&iacute; souřadnicov&eacute; syst&eacute;my.</p>
<hr />
<h2>Samostatn&aacute; t&eacute;mata areografie</h2>
<p>Areografie jako biogeografick&eacute; studium are&aacute;lu je obs&aacute;hl&eacute; t&eacute;ma, a tak byly někter&eacute; okruhy studijn&iacute;ch pozn&aacute;mek vyčleněny do samostatn&yacute;ch kapitol:</p>
<div className="od-sm">
<hr />
<h3><Link to="/biogeografie/areografie/makroekologicke-vlastnosti-arealu/">Vlastnosti are&aacute;lů</Link></h3>
<p>Vztahy mezi ekologick&yacute;mi vlastnostmi taxonu a velikost&iacute; či tvarem jejich are&aacute;lů. Kteř&iacute; živočichov&eacute; m&iacute;vaj&iacute; svůj are&aacute;l největ&scaron;&iacute;?</p>
<hr />
<h3><Link to="/biogeografie/areografie/faktory-ovlivnujici-rozsireni-druhu/">Faktory &scaron;&iacute;řen&iacute; druhů</Link></h3>
<p>Stručn&yacute; a rychl&yacute; pohled na faktory ovlivňuj&iacute;c&iacute; &scaron;&iacute;řen&iacute; druhů z areografick&eacute;ho hlediska. Co jsou rescue effect a sink populations?</p>
<hr />
<h3><Link to="/biogeografie/areografie/typy-rozsireni/">Typy roz&scaron;&iacute;řen&iacute; a&nbsp;are&aacute;lů</Link></h3>
<p>Vhled na z&aacute;kladn&iacute; dělen&iacute; typů roz&scaron;&iacute;řen&iacute; taxonů a na dělen&iacute; are&aacute;lů podle vegetačn&iacute;ch p&aacute;smů. Nav&iacute;c něco m&aacute;lo o endemitech a glaci&aacute;ln&iacute;ch reliktech.</p>
<hr />
    </div>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Areografie</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/disperze/biogeograficke-sireni/"><button className="inv">&larr;Biogeografické šíření druhů</button></Link>
    <Link to="/biogeografie/areografie/makroekologicke-vlastnosti-arealu/"><button className="inv">Makroekologické vlastnosti areálů &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
